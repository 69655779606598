import * as _isArguments2 from "is-arguments";

var _isArguments = "default" in _isArguments2 ? _isArguments2.default : _isArguments2;

import * as _isGeneratorFunction2 from "is-generator-function";

var _isGeneratorFunction = "default" in _isGeneratorFunction2 ? _isGeneratorFunction2.default : _isGeneratorFunction2;

import * as _whichTypedArray2 from "which-typed-array";

var _whichTypedArray = "default" in _whichTypedArray2 ? _whichTypedArray2.default : _whichTypedArray2;

import * as _isTypedArray2 from "is-typed-array";

var _isTypedArray = "default" in _isTypedArray2 ? _isTypedArray2.default : _isTypedArray2;

var exports = {};
var isArgumentsObject = _isArguments;
var isGeneratorFunction = _isGeneratorFunction;
var whichTypedArray = _whichTypedArray;
var isTypedArray = _isTypedArray;

function uncurryThis(f) {
  return f.call.bind(f);
}

var BigIntSupported = typeof BigInt !== "undefined";
var SymbolSupported = typeof Symbol !== "undefined";
var ObjectToString = uncurryThis(Object.prototype.toString);
var numberValue = uncurryThis(Number.prototype.valueOf);
var stringValue = uncurryThis(String.prototype.valueOf);
var booleanValue = uncurryThis(Boolean.prototype.valueOf);

if (BigIntSupported) {
  var bigIntValue = uncurryThis(BigInt.prototype.valueOf);
}

if (SymbolSupported) {
  var symbolValue = uncurryThis(Symbol.prototype.valueOf);
}

function checkBoxedPrimitive(value, prototypeValueOf) {
  if (typeof value !== "object") {
    return false;
  }

  try {
    prototypeValueOf(value);
    return true;
  } catch (e) {
    return false;
  }
}

exports.isArgumentsObject = isArgumentsObject;
exports.isGeneratorFunction = isGeneratorFunction;
exports.isTypedArray = isTypedArray; // Taken from here and modified for better browser support
// https://github.com/sindresorhus/p-is-promise/blob/cda35a513bda03f977ad5cde3a079d237e82d7ef/index.js

function isPromise(input) {
  return typeof Promise !== "undefined" && input instanceof Promise || input !== null && typeof input === "object" && typeof input.then === "function" && typeof input.catch === "function";
}

exports.isPromise = isPromise;

function isArrayBufferView(value) {
  if (typeof ArrayBuffer !== "undefined" && ArrayBuffer.isView) {
    return ArrayBuffer.isView(value);
  }

  return isTypedArray(value) || isDataView(value);
}

exports.isArrayBufferView = isArrayBufferView;

function isUint8Array(value) {
  return whichTypedArray(value) === "Uint8Array";
}

exports.isUint8Array = isUint8Array;

function isUint8ClampedArray(value) {
  return whichTypedArray(value) === "Uint8ClampedArray";
}

exports.isUint8ClampedArray = isUint8ClampedArray;

function isUint16Array(value) {
  return whichTypedArray(value) === "Uint16Array";
}

exports.isUint16Array = isUint16Array;

function isUint32Array(value) {
  return whichTypedArray(value) === "Uint32Array";
}

exports.isUint32Array = isUint32Array;

function isInt8Array(value) {
  return whichTypedArray(value) === "Int8Array";
}

exports.isInt8Array = isInt8Array;

function isInt16Array(value) {
  return whichTypedArray(value) === "Int16Array";
}

exports.isInt16Array = isInt16Array;

function isInt32Array(value) {
  return whichTypedArray(value) === "Int32Array";
}

exports.isInt32Array = isInt32Array;

function isFloat32Array(value) {
  return whichTypedArray(value) === "Float32Array";
}

exports.isFloat32Array = isFloat32Array;

function isFloat64Array(value) {
  return whichTypedArray(value) === "Float64Array";
}

exports.isFloat64Array = isFloat64Array;

function isBigInt64Array(value) {
  return whichTypedArray(value) === "BigInt64Array";
}

exports.isBigInt64Array = isBigInt64Array;

function isBigUint64Array(value) {
  return whichTypedArray(value) === "BigUint64Array";
}

exports.isBigUint64Array = isBigUint64Array;

function isMapToString(value) {
  return ObjectToString(value) === "[object Map]";
}

isMapToString.working = typeof Map !== "undefined" && isMapToString(new Map());

function isMap(value) {
  if (typeof Map === "undefined") {
    return false;
  }

  return isMapToString.working ? isMapToString(value) : value instanceof Map;
}

exports.isMap = isMap;

function isSetToString(value) {
  return ObjectToString(value) === "[object Set]";
}

isSetToString.working = typeof Set !== "undefined" && isSetToString(new Set());

function isSet(value) {
  if (typeof Set === "undefined") {
    return false;
  }

  return isSetToString.working ? isSetToString(value) : value instanceof Set;
}

exports.isSet = isSet;

function isWeakMapToString(value) {
  return ObjectToString(value) === "[object WeakMap]";
}

isWeakMapToString.working = typeof WeakMap !== "undefined" && isWeakMapToString(new WeakMap());

function isWeakMap(value) {
  if (typeof WeakMap === "undefined") {
    return false;
  }

  return isWeakMapToString.working ? isWeakMapToString(value) : value instanceof WeakMap;
}

exports.isWeakMap = isWeakMap;

function isWeakSetToString(value) {
  return ObjectToString(value) === "[object WeakSet]";
}

isWeakSetToString.working = typeof WeakSet !== "undefined" && isWeakSetToString(new WeakSet());

function isWeakSet(value) {
  return isWeakSetToString(value);
}

exports.isWeakSet = isWeakSet;

function isArrayBufferToString(value) {
  return ObjectToString(value) === "[object ArrayBuffer]";
}

isArrayBufferToString.working = typeof ArrayBuffer !== "undefined" && isArrayBufferToString(new ArrayBuffer());

function isArrayBuffer(value) {
  if (typeof ArrayBuffer === "undefined") {
    return false;
  }

  return isArrayBufferToString.working ? isArrayBufferToString(value) : value instanceof ArrayBuffer;
}

exports.isArrayBuffer = isArrayBuffer;

function isDataViewToString(value) {
  return ObjectToString(value) === "[object DataView]";
}

isDataViewToString.working = typeof ArrayBuffer !== "undefined" && typeof DataView !== "undefined" && isDataViewToString(new DataView(new ArrayBuffer(1), 0, 1));

function isDataView(value) {
  if (typeof DataView === "undefined") {
    return false;
  }

  return isDataViewToString.working ? isDataViewToString(value) : value instanceof DataView;
}

exports.isDataView = isDataView; // Store a copy of SharedArrayBuffer in case it's deleted elsewhere

var SharedArrayBufferCopy = typeof SharedArrayBuffer !== "undefined" ? SharedArrayBuffer : undefined;

function isSharedArrayBufferToString(value) {
  return ObjectToString(value) === "[object SharedArrayBuffer]";
}

function isSharedArrayBuffer(value) {
  if (typeof SharedArrayBufferCopy === "undefined") {
    return false;
  }

  if (typeof isSharedArrayBufferToString.working === "undefined") {
    isSharedArrayBufferToString.working = isSharedArrayBufferToString(new SharedArrayBufferCopy());
  }

  return isSharedArrayBufferToString.working ? isSharedArrayBufferToString(value) : value instanceof SharedArrayBufferCopy;
}

exports.isSharedArrayBuffer = isSharedArrayBuffer;

function isAsyncFunction(value) {
  return ObjectToString(value) === "[object AsyncFunction]";
}

exports.isAsyncFunction = isAsyncFunction;

function isMapIterator(value) {
  return ObjectToString(value) === "[object Map Iterator]";
}

exports.isMapIterator = isMapIterator;

function isSetIterator(value) {
  return ObjectToString(value) === "[object Set Iterator]";
}

exports.isSetIterator = isSetIterator;

function isGeneratorObject(value) {
  return ObjectToString(value) === "[object Generator]";
}

exports.isGeneratorObject = isGeneratorObject;

function isWebAssemblyCompiledModule(value) {
  return ObjectToString(value) === "[object WebAssembly.Module]";
}

exports.isWebAssemblyCompiledModule = isWebAssemblyCompiledModule;

function isNumberObject(value) {
  return checkBoxedPrimitive(value, numberValue);
}

exports.isNumberObject = isNumberObject;

function isStringObject(value) {
  return checkBoxedPrimitive(value, stringValue);
}

exports.isStringObject = isStringObject;

function isBooleanObject(value) {
  return checkBoxedPrimitive(value, booleanValue);
}

exports.isBooleanObject = isBooleanObject;

function isBigIntObject(value) {
  return BigIntSupported && checkBoxedPrimitive(value, bigIntValue);
}

exports.isBigIntObject = isBigIntObject;

function isSymbolObject(value) {
  return SymbolSupported && checkBoxedPrimitive(value, symbolValue);
}

exports.isSymbolObject = isSymbolObject;

function isBoxedPrimitive(value) {
  return isNumberObject(value) || isStringObject(value) || isBooleanObject(value) || isBigIntObject(value) || isSymbolObject(value);
}

exports.isBoxedPrimitive = isBoxedPrimitive;

function isAnyArrayBuffer(value) {
  return typeof Uint8Array !== "undefined" && (isArrayBuffer(value) || isSharedArrayBuffer(value));
}

exports.isAnyArrayBuffer = isAnyArrayBuffer;
["isProxy", "isExternal", "isModuleNamespaceObject"].forEach(function (method) {
  Object.defineProperty(exports, method, {
    enumerable: false,
    value: function () {
      throw new Error(method + " is not supported in userland");
    }
  });
});
export default exports;